import React, { useEffect } from "react";

const Preheader = () => (
	<><div id="preheader-wrapper">
		<div className="preheader">
			<div className="col">
				<p className="preheader-text">
					THIS SITE IS FOR U.S. HEALTHCARE PROFESSIONALS ONLY
				</p>
			</div>
		</div>
		<div id="fda-preheader" className="preheader-fda">
			<div className="col">
				<p className="preheader-text">
					Akebia announces FDA approval of a new treatment option. Read the press release <a href="https://ir.akebia.com/news-releases/news-release-details/akebia-receives-fda-approval-vafseor-vadadustat-tablets" target="_blank">here</a>.
				</p>
			</div>
		</div>
	</div></>
);

export default Preheader;
