import React from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import logo from "../images/akebia-logo-white.svg";

const Footer = () => (

	<>
		<div className="footer2">
			<div className="container">
				<div className="row">
					<div className="col">
						<Link to="/">
							<img
								src={logo}
								className="footer-logo"
								alt="Akebia Medical Affairs"
							/>
						</Link>
						<div className="job-code">MED-NA-US-0044 (v8.0) 03/24</div>
					</div>
					<div className="col">
						<a className="footer-link h5 noPush" href="/resources">
							Resources
						</a>
						<a className="footer-link" href="/resources#disease-education">
							Disease Education
						</a>
						<a className="footer-link" href="/resources#congress-activity">
							Congress Activity
						</a>
						<a className="footer-link" href="/resources#publication">
							Publications
						</a>
						<a className="footer-link" href="/resources#professional-resources">
							Professional Resources
						</a>
						<a className="footer-link" href="/resources#medical-information">
							Medical Information
						</a>
					</div>
					<div className="col">
						<a className="footer-link h5 footer-hash" href="/our-science/pipeline#clinical-programs">Clinical Programs</a>
						<a className="footer-link h5 noPush" href="/services">
							Services
						</a>
						<a className="footer-link footer-hash" href="/services#medical-information">
							Medical Information
						</a>
						<a className="footer-link footer-hash" href="/services#medical-education-grants">
							Medical Education Grants
						</a>
						<a className="footer-link footer-hash" href="/services#external-sponsored-research">
							External Sponsored Research
						</a>
					</div>
					<div className="col">
						<Link
							to="/contact-us"
							className="footer-link h5 noPush"
							activeClassName=""
							partiallyActive={true}
							state={{ filter: 'mirf' }}
						>
							Contact
						</Link>

						<Link
							to="/contact-us"
							className="footer-link"
							activeClassName=""
							partiallyActive={true}
							state={{ filter: 'mirf' }}
						>
							Medical Information Request
						</Link>
						<Link
							to="/contact-us"
							className="footer-link"
							activeClassName=""
							partiallyActive={true}
							state={{ filter: 'msl' }}
						>
							Connect with a Field Medical Expert
						</Link>
					</div>
				</div>
				<div id="footer-link-bottom" className="row">
					<div className="col">
						<a className="footer-link" href="https://akebia.com/" target="_blank" rel="noreferrer">
							Akebia.com
						</a>
						<span> / </span>
						<a className="footer-link" href="https://akebia.com/termsofuse/" target="_blank" rel="noreferrer">
							Terms of Use
						</a>
						<span> / </span>
						<a className="footer-link" href="https://akebia.com/privacy-policy/" target="_blank" rel="noreferrer">
							Privacy Policy
						</a>
						<span> / </span>
						<Link
							to="/sitemap"
							className="footer-link"
							activeClassName="header-link-active"
							partiallyActive={true}
						>
							Sitemap
						</Link>
					</div>
					<div className="col">
						<p className="footer-footnote">&copy; 2024 Akebia Therapeutics, Inc. All rights reserved</p>
					</div>
				</div>
			</div>
		</div>
	</>
);

// const Footer = () => (
// 	<div className="footer">
// 		<div className="container">
// 			<div className="row">
// 				<div className="col col-sm-6">
// 					<Link to="/">
// 						<img
// 							src={logo}
// 							className="footer-logo"
// 							alt="Akebia Medical Affairs"
// 						/>
// 					</Link>
// 				</div>
// 				<div className="col col-sm-6 footer-link-wrapper">
// 					<a
// 						href="https://akebia.com/"
// 						target="_blank"
// 						rel="noreferrer"
// 						className="footer-link"
// 					>
// 						Akebia.com
// 					</a>
// 					<span> / </span>
// 					<a
// 						href="https://akebia.com/termsofuse/"
// 						target="_blank"
// 						rel="noreferrer"
// 						className="footer-link"
// 					>
// 						Terms of Use
// 					</a>
// 					<span> / </span>
// 					<a
// 						href="https://akebia.com/privacy-policy/"
// 						target="_blank"
// 						rel="noreferrer"
// 						className="footer-link"
// 					>
// 						Privacy Policy
// 					</a>
// 					<span> / </span>
// 					<a
// 						href="/sitemap/"
// 						rel="noreferrer"
// 						className="footer-link"
// 					>
// 						Sitemap
// 					</a>
// 					<span> / </span>
// 					{/* <AnchorLink
// 						to="/services#research-grants"
// 						title="Research Grants"
// 						className="footer-link"
// 						stripHash
// 					/> */}
// 					<p className="footer-footnote">
// 						&copy; 2021 Akebia Therapeutics, Inc. All rights reserved.
// 					</p>
// 				</div>
// 				<div className="job-code">MED-NA-US-0044 (v2.0) 6/21</div>
// 			</div>
// 		</div>
// 	</div>
// );

export default Footer;
